import React, { useRef, useEffect } from 'react'
import { Clock, Zap, BookOpen, Image as ImageIcon, CreditCard, Truck } from "lucide-react"
import ReactGA from 'react-ga4'

interface Service {
  name: string;
  icon: React.FC<{ className?: string }>;
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export default function App() {
  useEffect(() => {
    ReactGA.initialize('G-86CGT1WERY')
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-16782088411');
  }, [])

  const contactRef = useRef<HTMLDivElement>(null)

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
      ReactGA.event({
        category: "Navigation",
        action: "Click",
        label: `Scrolled to ${id}`
      });
    }
  }

  const services: Service[] = [
    { name: 'Brochures', icon: ({ className }: { className?: string }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
        <line x1="3" y1="9" x2="21" y2="9"/>
        <line x1="9" y1="21" x2="9" y2="9"/>
      </svg>
    )},
    { name: 'Presentation Handouts', icon: ({ className }: { className?: string }) => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
        <line x1="3" y1="9" x2="21" y2="9"/>
        <path d="M8 3v18"/>
        <path d="M13 3v18"/>
      </svg>
    )},
    { name: 'Booklets', icon: BookOpen },
    { name: 'Posters', icon: ImageIcon },
    { name: 'Business Cards', icon: CreditCard },
  ]

  return (
    <div className="flex flex-col min-h-screen bg-white text-black">
      <header className="bg-white text-black py-4 shadow-md sticky top-0 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/24Print_Logo_Primary_RGB-ZJdd7qWwqH1RSphq7CSsRXjI2pnegm.png"
              alt="24 Print Logo"
              className="w-auto h-16 object-contain"
            />
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li><button onClick={() => scrollToSection('services')} className="text-black hover:text-cyan-600 transition-colors">Services</button></li>
              <li><button onClick={() => scrollToSection('why-us')} className="text-black hover:text-cyan-600 transition-colors">Why Us</button></li>
              <li><button onClick={() => scrollToSection('contact')} className="text-black hover:text-cyan-600 transition-colors">Contact</button></li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="flex-grow">
        <section className="relative py-20 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-cyan-500 via-blue-500 to-purple-600">
            <svg className="absolute bottom-0 left-0 right-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path fill="currentColor" fillOpacity="0.3" d="M0,96L48,112C96,128,192,160,288,186.7C384,213,480,235,576,213.3C672,192,768,128,864,128C960,128,1056,192,1152,208C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
          </div>
          <div className="container mx-auto text-center relative z-10">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-white drop-shadow-lg">Emergency Printing Services 24/7</h1>
            <p className="text-xl md:text-2xl mb-8 text-white drop-shadow">Your urgent printing needs, solved any time, day or night.</p>
            <button 
              className="px-6 py-3 bg-yellow-400 hover:bg-yellow-500 text-black font-bold rounded-lg text-lg animate-pulse"
              onClick={() => {
                scrollToSection('contact');
                ReactGA.event({
                  category: "Contact",
                  action: "Click",
                  label: "Submit Job Button"
                });
              }}
            >
              Submit Job
            </button>
          </div>
        </section>

        <section id="services" className="py-16 bg-white">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {services.map((service) => (
                <div key={service.name} className="bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow flex items-start">
                  <service.icon className="h-8 w-8 mr-4 text-cyan-600 flex-shrink-0" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{service.name}</h3>
                    <p>Professional {service.name.toLowerCase()} printed and ready in record time.</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="why-us" className="py-16 bg-gray-100">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-8">Why Choose Us</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="flex flex-col items-center text-center">
                <Clock className="h-12 w-12 mb-4 text-cyan-500" />
                <h3 className="text-xl font-semibold mb-2">24/7 Availability</h3>
                <p>We're always open, ready to handle your urgent printing needs.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <Zap className="h-12 w-12 mb-4 text-magenta-500" />
                <h3 className="text-xl font-semibold mb-2">Fast Turnaround</h3>
                <p>Quick printing and processing to meet your deadlines.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <svg className="h-12 w-12 mb-4 text-yellow-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M6 2L2 6v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6l-4-4H6zM2 6h20"/>
                  <path d="M6 2v4"/>
                  <path d="M18 2v4"/>
                  <path d="M8 12h8"/>
                  <path d="M8 16h8"/>
                </svg>
                <h3 className="text-xl font-semibold mb-2">Quality Prints</h3>
                <p>High-quality results, even on rush jobs.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <Truck className="h-12 w-12 mb-4 text-green-500" />
                <h3 className="text-xl font-semibold mb-2">Delivery To Your Door</h3>
                <p>We offer convenient delivery services to bring your prints directly to you.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="py-16 bg-white" ref={contactRef}>
          <div className="container mx-auto max-w-md text-center">
            <h2 className="text-3xl font-bold mb-8">Contact Us</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-semibold mb-2">Email Us</h3>
                <a 
                  href="mailto:info@24print.co" 
                  className="text-cyan-600 hover:text-cyan-700 text-lg"
                  onClick={() => {
                    ReactGA.event({
                      category: "Contact",
                      action: "Click",
                      label: "Email Link"
                    });
                  }}
                >
                  info@24print.co
                </a>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Call Us</h3>
                <a 
                  href="tel:+16785617223" 
                  className="text-cyan-600 hover:text-cyan-700 text-lg"
                  onClick={() => {
                    ReactGA.event({
                      category: "Contact",
                      action: "Click",
                      label: "Phone Link"
                    });
                  }}
                >
                  (678) 561-7223
                </a>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">Location</h3>
                <p className="text-lg">Roswell, GA 30076</p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-black text-white py-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 24 Print. All rights reserved.</p>
          <p className="mt-2">Roswell, GA 30076</p>
          <p className="mt-2">Emergency Line: (678) 561-7223</p>
        </div>
      </footer>
    </div>
  )
}